const en = {
  btn: {
    registration: "Sign Up",
    openAccount: "Open Account",
    join: "Join",
    login: "Log In",
    register: "Sign Up",
    continue: "Continue",
    begin: "Open an account",
  },
  menu: {
    home: "Home",
    markets: "Markets",
    about: "About Us",
    contacts: "Contacts",
    platform: "Platform",
    faq: "FAQ",
  },
  subMenu: {
    forex: "Forex",
    indices: "Indices",
    stock: "Stock",
    currency: "Cryptocurrencies",
    commodities: "Commodities",
  },
  form: {
    name: "Name",
    phone: "Phone number",
  },
  privacy: "Privacy Policy",
  assets: "Assets",
  support: "Support",
  leverageTo: "Shoulder up",
  depositFrom: "Deposit from",
  cities: ["Great Britain", "Cyprus", "France"],
  popularAssets: "Popular assets",
  worksDay1: "Mon-Fri",
  worksDay2: "Sat-Sun",
  ourAddress: "Our address",
};

export default en;
